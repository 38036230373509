// =============================================================================
// Style > Components > Preloader
// =============================================================================

.phone-preloader {
	display: flex;
	align-items: center;
	justify-content: center;

	margin: auto;
	width: 100px;
	height: 100px;
	position: relative;

	.icon {
		font-size: 48px;
		color: var(--accent-colour-2);
	}

	&__ring1,
	&__ring2 {
		position: absolute;
		border-radius: 50%;
		border: 3px solid;
		animation: lds-dual-ring 1000ms linear infinite;
	}

	&__ring1 {
		top: 0px;
		left: 0px;
		width: 100px;
		height: 100px;
		border-color: var(--accent-colour-1) transparent var(--accent-colour-1) transparent;
	}

	&__ring2 {
		top: 7px;
		left: 7px;
		width: 86px;
		height: 86px;
		border-color: var(--accent-colour-2) transparent var(--accent-colour-2) transparent;
		animation-direction: reverse;
	}

	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.phone-preloader.phone-preloader--idle {
	.phone-preloader__ring1,
	.phone-preloader__ring2 {
		animation: none;
	}
	.phone-preloader__ring1 {
		border-color: var(--accent-colour-1);
	}
	.phone-preloader__ring2 {
		border-color: var(--accent-colour-2);
	}
}
