$decision-log-border-radius: 10px;
// $decision-log-border-colour: #e7e8eb;
$decision-log-border-colour: $border-color;
$decision-log-focussed-border-colour: var(--accent-colour-1);

.decision-log {
	height: 0px;
	flex-grow: 1;

	display: flex;
	flex-direction: column;

	&__header {
		display: flex;
		justify-content: space-around;
	}

	&__scroller {
		height: 0px;
		flex-grow: 1;
		overflow: auto;
	}

	&__inner {
		display: grid;
		padding: 8px 0px;
		grid-column-gap: 10px;
		grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
		grid-row-gap: 2px;

		h3 {
			margin-bottom: 0px;
		}
		> * {
			display: flex;
			flex-shrink: 0;
		}
		> *:last-child {
			margin-bottom: 16px;
		}
	}

	&__metadata {
		grid-column: 1 / span 2;

		font-size: $small-font-size;

		opacity: 0.6;

		padding-left: 4px;
		padding-right: 4px;
		margin-bottom: $vertical-spacing * 0.5;

		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__author {
		padding: 0px;
		margin-left: 0.5rem;
		margin-right: 1rem;
	}

	&__scroller {
		flex-shrink: 1;
		padding: 0px;
	}

	&__advice,
	&__rationale {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		position: relative;
		min-height: 4rem;

		textarea {
			border-radius: $decision-log-border-radius;
			resize: none;
			cursor: text;

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%; // This is needed for Firefox

			&:disabled {
				cursor: default;
			}

			&:focus {
				border: solid 1px $decision-log-focussed-border-colour;
			}
		}

		textarea,
		.resizer {
			width: 100%;
			font-size: $base-font-size;
			white-space: pre-wrap;
			overflow-wrap: break-word;
			padding: 8px;
			line-height: normal;
		}

		.resizer {
			opacity: 0;
			border: solid 1px transparent;
		}
	}

	&__box {
		border: solid 1px $decision-log-border-colour;
		width: 0px;
		flex-grow: 1;
		background-color: white;
		padding: 8px 12px;
		white-space: pre-wrap;
		word-break: break-word;
		user-select: auto;

		border-radius: $decision-log-border-radius;
	}

	&__translate {
		color: var(--accent-colour-1) !important;
		transition: opacity 200ms;
		opacity: 0.8;

		&:hover,
		&--active {
			opacity: 1;
		}

		cursor: pointer;
		* {
			cursor: pointer;
		}
	}

	&__auth-and-time {
		flex-grow: 1;
	}
}
