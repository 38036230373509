$orange: #f07e26;
$offorange: #aaaaaa;
$darkorange: #444444;
$lightblue: #004c72;
$blue: #0c1a2f;
$darkblue: hsl(216, 59%, 8%);
$orange: #f07e26;

body.networkrail {
	--accent-colour-1: #{$orange};
	--header-border-colour: #{$orange};

	--button-colour: #{$lightblue};
	--button-hover-colour: #{$orange};
	--accent-colour-2: #{$orange};
	--button-2-hover-colour: #{$lightblue};

	--header-colour: #{$blue};
	--header-text-colour: white;

	--border-colour-2: #{$orange};

	// --heading-colour: #{$blue};
	--heading-text-colour: #{$blue};
	--heading-border-colour: #{$orange};

	--sidebar-colour: #{$blue};

	// --login-text-colour: #{white};
	--login-button-colour: #{$lightblue};
	--login-button-icon-colour: white;

	--login-button-hover-colour: #{$orange};
	--login-button-hover-icon-colour: white;

	--login-background-colour: #{$blue};
	--logo-4-width: 275px;
	--logo-1: url('../../themes/networkrail/network_logo_reverse.png');
	--logo-3: url('../../themes/networkrail/network_logo_320.png');
	--logo-4: url('../../themes/networkrail/network_logo_320_reverse.png');

	--scrollbar-background-colour: #{transparentize($orange, 0.9)};
}
