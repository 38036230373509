.tutor-view {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;

	display: flex;
	flex-direction: column;
	background-color: $lightgrey;

	.tutor-sidebar {
		z-index: 3;
	}

	&__main {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		overflow: hidden;
	}

	&__content,
	&__content-overlay,
	&__content-wrapper {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: center;
	}

	&__content {
		overflow: auto;
		position: relative;
		> :first-child {
			z-index: 2;
		}
	}

	&__content-overlay {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: transparentize($color: $lightgrey, $amount: 0.2);
	}

	&__casedetails {
		display: flex;
		flex-direction: row;
		overflow: hidden;
		flex-shrink: 0;

		.session-clock {
			flex-grow: 1;
		}
	}

	.button {
		button {
			font-weight: bold;
		}
	}
}
