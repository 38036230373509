@use 'sass:math';

// Variables
// --------------------------

$fa-font-path: '../webfonts' !default;
$fa-font-size-base: 16px !default;
$fa-font-display: block !default;
$fa-css-prefix: fa !default;
$fa-version: '5.15.1' !default;
$fa-border-color: #eee !default;
$fa-inverse: #fff !default;
$fa-li-width: 2em !default;
$fa-fw-width: math.div(20em, 16);
$fa-primary-opacity: 1 !default;
$fa-secondary-opacity: 0.4 !default;

// Convenience function used to set content property
@function fa-content($fa-var) {
	@return unquote('"#{ $fa-var }"');
}

$fa-var-arrow-circle-left: \f0a8;
$fa-var-arrow-left: \f060;
$fa-var-at: \f1fa;
$fa-var-briefcase: \f0b1;
$fa-var-camera: \f030;
$fa-var-chalkboard-teacher: \f51c;
$fa-var-check: \f00c;
$fa-var-clipboard-list: \f46d;
$fa-var-cog: \f013;
$fa-var-comments-alt: \f4b6;
$fa-var-copy: \f0c5;
$fa-var-desktop: \f108;
$fa-var-download: \f019;
$fa-var-envelope: \f0e0;
$fa-var-facebook-f: \f39e;
$fa-var-file-alt: \f15c;
$fa-var-globe: \f0ac;
$fa-var-history: \f1da;
$fa-var-images: \f302;
$fa-var-instagram: \f16d;
$fa-var-key: \f084;
$fa-var-linkedin: \f08c;
$fa-var-map-marker-alt: \f3c5;
$fa-var-microphone: \f130;
$fa-var-microphone-slash: \f131;
$fa-var-newspaper: \f1ea;
$fa-var-paper-plane: \f1d8;
$fa-var-phone: \f095;
$fa-var-power-off: \f011;
$fa-var-skype: \f17e;
$fa-var-th: \f00a;
$fa-var-times: \f00d;
$fa-var-trash-alt: \f2ed;
$fa-var-twitter: \f099;
$fa-var-user: \f007;
$fa-var-user-plus: \f234;
$fa-var-users: \f0c0;
$fa-var-viber: \f409;
$fa-var-video: \f03d;
$fa-var-video-slash: \f4e2;
$fa-var-volume: \f6a8;
$fa-var-volume-down: \f027;
$fa-var-volume-mute: \f6a9;
$fa-var-volume-slash: \f2e2;
$fa-var-volume-up: \f028;
$fa-var-whatsapp: \f232;
$fa-var-youtube: \f167;
