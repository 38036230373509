.previous-session-message {
	@extend .status-bar-message;

	background-color: var(--accent-colour-2);
	color: $white;

	text-align: center;
	height: 29px;
	line-height: 29px;
	font-weight: bold;
	border-radius: 99px;
	padding: 0px;
	margin: 0 1rem;
	display: flex;

	border: 2px solid var(--accent-colour-2);

	.icon-button {
		height: 25px;
		width: 25px;
		font-size: 17px;
		.icon {
			color: $white;
		}
	}

	.button {
		max-height: 100%;
		flex-shrink: 0;
		width: auto;
		button {
			flex-shrink: 0;
			max-height: 100%;
			margin: 0 !important;
			line-height: normal;
			padding-top: 4px;
			padding-bottom: 0;
			height: 100%;
		}
		button:hover {
			transition: background-color 0ms;
		}
		button:active {
			background: $button-primary-background;
		}
	}

	> span {
		@include ellipsisTruncate;
		width: 1px;
		flex-grow: 1;
		margin-left: 1rem;
		margin-right: 1rem;
	}
}
