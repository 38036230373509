.modal {
	background-color: $modal-background-color;
	border: none;
	border-radius: $small-border-radius;
	padding: 12px 16px;
	position: relative;
	box-shadow: 0px 25px 25px -15px rgba(0, 0, 0, 0.3);
	max-height: 99%;
	max-width: 99%;
	min-width: 400px;

	display: flex;
	flex-direction: column;

	img {
		object-fit: contain;
		max-width: 100%;
		max-height: calc(100vh - 100px);
		object-position: center;
	}
	audio {
		min-width: 400px;
	}
	video {
		max-width: 100%;
		max-height: calc(100vh - 100px);
		object-position: center;
	}

	&__content {
		display: flex;
		max-height: 87vh;
		> * {
			width: 100%;
		}

		.preloader {
			margin-top: 24px;
			margin-bottom: 24px;
		}
	}

	&__header {
		display: flex;
		flex-direction: row;
		margin-bottom: 12px;
		flex-shrink: 0;
		.icon-button {
			width: 32px;
			height: 32px;
			padding: 6px;
			margin: 0px;
			margin-left: 24px;
			.icon {
				font-size: 16px;
				color: black;
			}
		}
		.icon-button:hover {
			.icon {
				color: white;
			}
		}
	}

	&__title {
		flex-grow: 1;
		font-size: 22px;
		font-weight: bold;
		line-height: 24px;
		display: flex;
		align-items: center;
	}
}

.modal__container {
	position: fixed;
	background-color: $modal-overlay-background-color;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 5;

	display: flex;
	align-items: center;
	justify-content: center;
	animation: 0.4s fadeInAnim cubic-bezier(0.075, 0.82, 0.165, 1);
	opacity: 1;
}

@keyframes fadeInAnim {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.modal-enter {
	opacity: 0.01;
	.modal {
		top: 2000px;
	}
}

.modal-enter.modal-enter-active {
	opacity: 1;
	transition: opacity 300ms ease-in;
	.modal {
		transition: top 300ms ease-out;
		top: 0px;
	}
}

.modal-leave {
	opacity: 1;
	.modal {
		top: 0px;
	}
}

.modal-leave.modal-leave-active {
	opacity: 0.01;
	transition: opacity 300ms ease-in;
	.modal {
		transition: top 300ms ease-in;
		top: 2000px;
	}
}

.media-modal {
	display: flex;
	flex-grow: 1;
	justify-content: center;
}
