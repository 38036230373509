/* Style placeholder, so it can be used by other components (like alert) */
%button {
	display: flex;
	position: relative;

	width: 100%;

	margin: 4px 0px;

	padding-right: 24px;
	padding-left: 24px;
	padding-top: 9px;
	padding-bottom: 7px;

	font-family: $base-font-family;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;
	font-size: $button-font-size;
	line-height: 1.1rem;

	outline: none;
	border: none;
	border-radius: $button-border-radius;

	color: var(--button-text-colour);
	background: $button-primary-background;
	transition: background-color 500ms, color 500ms;

	cursor: pointer;
	* {
		cursor: pointer;
	}

	&:hover {
		transition: none;
		background: var(--button-hover-colour);
	}

	text-transform: uppercase;
	font-weight: 800;
	letter-spacing: 1px;
	line-height: 1.1rem;
	border-radius: 200px;

	.button__text {
		flex-grow: 1;
		@include ellipsisTruncate;
	}
}

.button {
	button {
		@extend %button;
	}
	.icon {
		height: 18px;
		width: 18px;
		display: block;
		position: relative;
		fill: white;
		left: -12px;
		opacity: 0.9;
	}

	&:active:not(.button--disabled) {
		button {
			transform: translateY(2px);
			box-shadow: none;
		}
	}

	&--secondary {
		button {
			color: $button-secondary-color;
			background: $button-secondary-background;
			&:hover {
				background: $button-secondary-background;
				background: $button-secondary-background;
			}
			// box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.1);
		}
	}

	&--primary {
		button {
			color: $white;
			background: var(--accent-colour-2);
			&:hover {
				background: var(--accent-colour-2);
				background: var(--button-2-hover-colour);
			}
			// box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.1);
		}
	}
}

.section--disabled .button,
.button--disabled,
.button--disabled:active {
	button {
		cursor: default;
		// margin: 4px 0px;
		background: $button-disabled-background;
		&:hover {
			background: $button-disabled-background;
		}
		color: $button-disabled-color;
		border-color: $button-disabled-color;
		// box-shadow: 0px 3px 0px rgba(0, 0, 0, 0);
	}
	cursor: default;
	* {
		cursor: default !important;
	}
}

button::-moz-focus-inner {
	border: 0;
}
