@font-face {
	font-family: 'icomoon';
	src: url('fonts/icomoon/fonts/icomoon.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-speaker-view-light:before {
	content: '\e934';
}
.icon-arrow-circle-left:before {
	content: '\e909';
}
.icon-arrow-left:before {
	content: '\e931';
}
.icon-at:before {
	content: '\e930';
}
.icon-briefcase:before {
	content: '\e92f';
}
.icon-camera:before {
	content: '\e92e';
}
.icon-chalkboard-teacher:before {
	content: '\e923';
}
.icon-check:before {
	content: '\e92d';
}
.icon-circle-arrow-right:before {
	content: '\e932';
}
.icon-clipboard-list:before {
	content: '\e92c';
}
.icon-cog:before {
	content: '\e90a';
}
.icon-comments-alt:before {
	content: '\e90b';
}
.icon-copy:before {
	content: '\e90c';
}
.icon-desktop:before {
	content: '\e933';
}
.icon-download:before {
	content: '\e90d';
}
.icon-envelope:before {
	content: '\e90e';
}
.icon-facebook-f:before {
	content: '\e924';
}
.icon-file-alt:before {
	content: '\e90f';
}
.icon-film:before {
	content: '\e905';
}
.icon-globe:before {
	content: '\e910';
}
.icon-grid:before {
	content: '\e906';
}
.icon-grid-2:before {
	content: '\e903';
}
.icon-grid-3:before {
	content: '\e901';
}
.icon-history:before {
	content: '\e911';
}
.icon-images:before {
	content: '\e912';
}
.icon-instagram:before {
	content: '\e925';
}
.icon-key:before {
	content: '\e913';
}
.icon-linkedin:before {
	content: '\e926';
}
.icon-map-marker-alt:before {
	content: '\e914';
}
.icon-microphone:before {
	content: '\e915';
}
.icon-microphone-slash:before {
	content: '\e916';
}
.icon-newspaper:before {
	content: '\e917';
}
.icon-paper-plane:before {
	content: '\e918';
}
.icon-pen:before {
	content: '\e908';
}
.icon-pen-to-square:before {
	content: '\e907';
}
.icon-phone:before {
	content: '\e919';
}
.icon-power-off:before {
	content: '\e91a';
}
.icon-skype:before {
	content: '\e927';
}
.icon-speaker-view:before {
	content: '\e902';
}
.icon-table-cells-large:before {
	content: '\e904';
}
.icon-times:before {
	content: '\e91b';
}
.icon-trash-alt:before {
	content: '\e91c';
}
.icon-twitter:before {
	content: '\e928';
}
.icon-user:before {
	content: '\e900';
}
.icon-user-plus:before {
	content: '\e91d';
}
.icon-users:before {
	content: '\e91e';
}
.icon-viber:before {
	content: '\e929';
}
.icon-video:before {
	content: '\e91f';
}
.icon-video-slash:before {
	content: '\e920';
}
.icon-volume-slash:before {
	content: '\e921';
}
.icon-volume-up:before {
	content: '\e922';
}
.icon-whatsapp:before {
	content: '\e92a';
}
.icon-youtube:before {
	content: '\e92b';
}
