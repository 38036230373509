$bubble-heading-border-color: var(--heading-border-colour);
$bubble-heading-background-color: var(--heading-colour);
$bubble-heading-text-color: var(--heading-text-colour);
$bubble-heading-padding: 0rem 3.5rem;
$bubble-heading-height: 42px;

// .bubble-heading {
// 	display: inline-block;
// 	height: 42px;
// 	line-height: 36px;
// 	font-size: 22px;
// 	color: var(--heading-text-colour);
// 	font-weight: 600;
// 	text-align: center;
// 	max-width: 100%;
// 	text-overflow: ellipsis;
// 	white-space: nowrap;
// 	overflow: hidden;
// 	overflow: visible;
// 	transform: translateY(0px);
// 	margin: 0 0 3px;
// }

.bubble-heading {
	display: inline-block;

	// padding: $bubble-heading-padding;
	height: $bubble-heading-height;
	line-height: $bubble-heading-height - 2px;
	// line-height: 36px;

	margin: 0;
	font-size: $heading-font-size;
	color: var(--heading-text-colour);
	font-weight: $heading-font-weight;
	// font-family: var(--heading-font-family);
	text-align: center;

	max-width: 100%;

	@include ellipsisTruncate;
	overflow: visible;
	transform: translateY(-3px);
	// transform: translateY(0px);
	margin-bottom: 3px;

	// border-bottom: solid 2px var(--heading-border-colour);

	&::after {
		content: '';
		height: 3px;
		background-color: var(--heading-border-colour);
		width: 80%;
		display: block;
		margin: 0 auto;
		transform: translateY(0px);
	}
}

// .bubble-heading--alert {
// 	animation: bubble-heading__flashred steps(1) 1s infinite;
// }

// /* prettier-ignore */
// @keyframes bubble-heading__flashred {
// 	50% {
// 		color: $red;
// 	}
// }
