.media-item {
	@include configPanelCard();
	// TODO: DRY out reuse between this and passwords/prev sessions
	display: flex;
	padding: 8px 16px 8px 10px;
	background-color: white;
	align-items: center;

	cursor: pointer;
	* {
		cursor: pointer;
	}

	&__icon {
		width: 35px;
		height: 35px;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: green;
		border-radius: 99px;
		margin-right: 1rem;
	}

	&__middle {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		width: 1px;
	}

	&__name {
		font-size: 16px;
		word-break: break-all;
		word-break: break-word; // Not supported in Firefox: https://www.w3schools.com/cssref/css3_pr_word-break.asp
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin: 0px;
		line-height: 24px;
		.icon {
			color: var(--heading-text-colour);
			margin-left: 0.5rem;
		}
	}

	.uploaded {
		font-size: $small-font-size;
		opacity: 0.5;
		flex-grow: 1;
		margin-top: 4px;
	}

	&--unseen {
		background-color: var(--header-colour);
		color: white;
	}

	.icon-select {
		margin-right: 1rem;
		.icon-select____control {
			border-radius: $small-border-radius;
		}
	}

	.icon-button {
		margin-left: 10px;
		margin-right: -8px;
	}
}
