body {
	padding: 0;
	margin: 0;
	overflow: hidden;
}

* {
	box-sizing: border-box;
	user-select: none;
	cursor: default;
	scrollbar-width: thin;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid $border-color;
	margin: 8px 0;
	padding: 0;
}

.row {
	display: flex;
	flex-direction: row;
}

/* Emergency exceptions */
div.message__text,
div.decision-log__box {
	user-select: text !important;
	cursor: auto !important;
}

// Custom scrollbar size/colours
*::-webkit-scrollbar-track {
	background-color: var(--scrollbar-background-colour);
	border-radius: 5px;
}

*::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
	background-color: var(--header-colour);
	border: 1px solid var(--header-colour);
	border-radius: 5px;
}

* {
	scrollbar-color: var(--header-colour) var(--scrollbar-background-colour);
}
