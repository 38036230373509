.modal.modal-media-upload {
	width: 66%;
	padding-bottom: 16px;

	.modal__content {
		flex-direction: column;
	}
	.error {
		margin-top: 1rem;
	}
	.react-fine-uploader-file-input-container {
		background: $button-primary-background;
		border: none;
		color: $button-primary-color;
		width: 150px;
	}
	.react-fine-uploader-gallery-files {
		padding-top: 30px;
		padding-left: 20px;
	}
	.react-fine-uploader-gallery-file {
		height: auto;
	}
	.fine-uploader-dropzone-container {
		input {
			cursor: pointer;
			* {
				cursor: pointer;
			}
		}
	}
}
