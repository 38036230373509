// =================================================================================================
// New:

.side-call {
	max-width: var(--filmstrip-width);
	min-width: var(--filmstrip-width);
	width: var(--filmstrip-width);
	flex-shrink: 0;
	border-left: solid 1px var(--border-colour-2);
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: white;
	padding-top: 4px;

	padding-left: 4px;
	padding-right: 4px;

	&__participants {
		height: 1px;
		flex-grow: 1;
	}

	.video-call-participant {
		.video-call-participant__inner {
			border-color: $mediumgrey;
		}

		// Ensure they appear clickable
		cursor: pointer;
		* {
			cursor: pointer;
		}
	}
}

// =================================================================================================
// Old:

#side-call {
	max-width: var(--filmstrip-width);
	min-width: var(--filmstrip-width);
	width: var(--filmstrip-width);
	flex-shrink: 0;
	border-left: solid 1px var(--border-colour-2);
	display: flex;
	flex-direction: column;
	height: 100%;

	&__content {
		height: 1px;
		flex-grow: 1;
	}
}
