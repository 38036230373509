.team-badge {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__circle {
		border-radius: 99px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;

		transition: background-color 500ms ease-out;

		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
