.participant.participant {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;

	h2 {
		margin: 0;
		line-height: 32px;
	}

	p {
		line-height: 20px;
		margin: 0;
		font-size: 12px;
		text-transform: uppercase;
		color: rgba(0, 0, 0, 0.5);
		.icon {
			color: rgba(0, 0, 0, 0.5);
			margin-right: 0.5rem;
		}
	}

	.col {
		position: relative;
		overflow: visible;
		align-items: center;
		justify-content: center;

		.pill {
			margin-top: 5px;
			margin-bottom: 4px;
		}

		p {
			/* position: absolute; */
			text-align: center;
			left: 0;
			right: 0;
			bottom: -18px;
		}
	}
}
