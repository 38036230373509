.tutor-sessions {
	@include tutorConfigPanel();
	max-width: 1000px;
}

.main-content-message {
	background-color: $disabledgrey;
	padding: 16px 32px;
	white-space: pre-wrap;
	text-align: center;
	margin: 32px;
	border-radius: $small-border-radius;
	line-height: 24px;
}
