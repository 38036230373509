.scheduleRow {
	display: flex;
	flex-direction: column;

	border: solid 1px $border-color;
	border-radius: $small-border-radius;
	background-color: $lightgrey;
	padding: 4px 12px 4px 32px;

	cursor: move;
	* {
		cursor: move;
	}
	select {
		cursor: default;
	}
	.row:first-child {
		margin-left: -32px;
		input,
		select {
			height: 33px;
		}
	}
	// > .row:not(:first-of-type) {
	// padding-left: 32px;
	// }

	transition: border-color 300ms;
	&:hover {
		border-color: var(--accent-colour-1);
		transition: none;
	}

	&__index {
		color: $base-text-color;
		text-align: center;
		opacity: 0.5;
		width: 32px;
		overflow: visible;
	}

	.scheduleRow__message {
		opacity: 0.5;
		flex-grow: 1;
	}

	.media_dropdown {
		width: 21rem;
		flex-grow: 0;
		flex-shrink: 1;
	}

	.icon-button {
		height: 44px;
		width: 44px;
		padding: 0;
		margin: 0;
		flex-shrink: 0;
	}

	.button {
		margin-left: 6px;
	}

	$spacing: 0.5rem;

	.label {
		margin-right: $spacing;
	}

	input {
		margin-left: $spacing;
		margin-right: $spacing;
	}

	select {
		margin-left: 0;
		margin-right: $spacing;
	}

	.textarea {
		margin-bottom: 6px;
	}

	textarea {
		resize: none;
	}
}

.schedule-row-wrapper {
	margin-bottom: 12px;
}
