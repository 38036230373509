$silver: #ebebeb;
$offsilver: #aaaaaa;
$darksilver: #444444;
$mazda-black: #202020;
$mazda-blue: #007dc3;

body.mazda {
	--accent-colour-1: #{$silver};

	--button-colour: #{$mazda-blue};
	--button-hover-colour: #{lighten($mazda-blue, 25%)};
	--accent-colour-2: #{$darksilver};
	--button-2-hover-colour: #{$mazda-blue};

	--header-colour: #{$mazda-black};
	--header-text-colour: white;
	--header-border-colour: #{$mazda-blue};

	--border-colour-2: #{$silver};

	--heading-colour: #{$mazda-black};
	--heading-text-colour: #{$mazda-black};
	--heading-border-colour: #{$silver};

	--sidebar-colour: #{$darksilver};

	--login-button-colour: #{$silver};
	--login-button-icon-colour: #{$mazda-black};

	--login-button-hover-colour: #{$mazda-blue};
	--login-button-hover-icon-colour: white;

	--login-background-colour: #{$mazda-black};
	--logo-4-width: 275px;
	--logo-1: url('../../themes/mazda/mazda-logo.png');
	--logo-3: url('../../themes/mazda/Mazda-100th-Anniversary-Logo-1000x713.png');
	--logo-4: url('../../themes/mazda/mazda-emblem-2.png');
	--scrollbar-background-colour: #{transparentize($mazda-blue, 0.9)};
}
