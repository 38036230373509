$height: 3rem;
$icon-size: 23px;

$default-text-colour: $base-text-color;
$default-background-colour: white;

$default-icon-colour: white;
$default-icon-background-colour: #94a3b3;

$seen-text-colour: white;
$seen-background-colour: #94a3b3;

.media-feed-item {
	color: $default-text-colour;
	border-radius: 99px;
	height: $height;
	display: flex;
	min-width: 260px;

	outline: none;

	cursor: pointer;
	* {
		cursor: pointer;
	}

	&__title {
		margin: 0;
		margin-top: 5px;
		line-height: 19px;
		font-size: 13px;
		padding-right: 1rem;
		font-weight: 500;

		@include ellipsisTruncate;
	}

	&__details {
		position: relative;

		background-color: $red;
		color: $white;

		border-radius: 99px;
		width: 1px;
		flex-grow: 1;
		padding-left: 1rem;
		padding-right: 0.2rem;

		display: flex;

		.left {
			display: flex;
			flex-direction: column;
			width: 1px;
			flex-grow: 1;
			position: relative;
		}

		.right {
			margin-left: -0.3rem;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			.icon-button {
				// border: solid 1px rgba(255, 255, 255, 0.6);
				&:not(:hover) {
					background-color: rgba(255, 255, 255, 0.2);
				}
				border-radius: 999px;
				height: calc(#{$height} - 10px);
				width: calc(#{$height} - 10px);
				margin-right: 1px;

				.icon {
					font-size: calc(#{$height} - 28px);
					color: $white;
					fill: $white;
				}
			}
		}
	}

	&__time,
	&__opentime {
		opacity: 0.6;
		font-size: $small-font-size;
		margin-top: 1px;
		text-align: left;
		padding-right: 0.4rem;
	}

	&__opentime {
		position: absolute;
		right: 5px;
		bottom: 3px;
	}

	&__icon-container {
		width: $height;
		height: $height;
		margin-right: 0.5rem;
		border-radius: 99px;

		background-color: $default-icon-background-colour;

		display: flex;
		align-items: center;
		justify-content: center;

		.icon {
			display: block;
			width: auto;
			height: $icon-size;
			font-size: $icon-size;
			color: $default-icon-colour;
			text-align: center;
		}
	}
}

// =================================================================================================

.media-feed-item--recent {
	.media-feed-item__details,
	.media-feed-item__details .icon {
		animation: flashRed 1s;
		animation-iteration-count: 20;
	}
}

// =================================================================================================

.media-feed-item--seen {
	.media-feed-item__details {
		background-color: $seen-background-colour;
		color: $seen-text-colour;
		fill: $seen-text-colour;
	}
	.media-feed-item__details .icon {
		color: $seen-text-colour;
		fill: $seen-text-colour;
	}
}

// =================================================================================================

@keyframes flashRed {
	0% {
		background-color: $default-background-colour;
		color: $default-text-colour;
		fill: $default-text-colour;
	}
	49% {
		background-color: $default-background-colour;
		color: $default-text-colour;
		fill: $default-text-colour;
	}
	50% {
		background-color: $red;
		color: $white;
		fill: $white;
	}
	100% {
		background-color: $red;
		color: $white;
		fill: $white;
	}
}
