@use 'sass:math';

$button-border-radius: 100px;
$input-border-radius: 6px;
$small-border-radius: 10px;
$xlarge-border-radius: 10px;

$vertical-spacing: 1.4rem;
$group-view-section-border-radius: $xlarge-border-radius;

$logo-height: 55%;

// $side-call-width: 240px;

:root {
	--filmstrip-width: 240px;
}

// $side-call-video-ratio: 16 / 9;
// $side-call-video-ratio: 4 / 3;
$side-call-video-ratio: math.div(6, 4);

// -------------------------------------------------------------------------------------------------

$red: #e00000;

$off-black: #252525; // base text color
$darkgrey: #707070; // text in info sections
$grey: #c9c5c8; // disabled field border
$mediumgrey: #d9d9dd; // borders, input field borders

$disabledgrey: #e7e9ef; // disabled input fields

// $lightgrey: #edeef1; // general background colour
$lightgrey: #f1f2f4; // general background colour
$lightlightgrey: #faf9fb; // parent containers
$white: white;

$view360cyan: #08c3d9;
$view360magenta: #ff0099;
$view360purple: #1b1464;

:root {
	--accent-colour-1: #{$view360purple};
	--accent-colour-2: #{$view360magenta};

	--button-colour: #{$view360purple};
	--button-hover-colour: #{lighten($view360purple, 10%)};
	--button-text-colour: white;

	--button-2-colour: #{$view360magenta};
	--button-2-hover-colour: #{lighten($view360magenta, 5%)};
	--button-2-text-colour: white;

	--header-colour: #{$view360purple}; // main brand colour
	--header-text-colour: white;
	--header-border-colour: #{$view360magenta};

	--heading-colour: #{$view360purple};
	--heading-border-colour: #{$view360magenta};
	--heading-text-colour: #{$view360purple};
	--heading-font-family: Rubrik, sans-serif;

	--background-colour-1: white;
	--background-colour-2: #f3f4f7;

	--border-colour-1: #ceced4;
	--border-colour-2: #ceced4;

	--disabled-border-colour: #c1bdc0;

	--sidebar-colour: #{darken($view360purple, 5%)};

	--login-background-colour: linear-gradient(225deg, #{$view360magenta} 21%, #{$view360purple} 50%);
	--login-text-colour: black;

	--login-logo-width: 300px;

	--login-button-hover-colour: #{$view360magenta};
	--login-button-hover-icon-colour: white;
	--login-button-colour: #{$view360purple};
	--login-button-icon-colour: white;

	--logo-1: url('../img/view360-2021-icon-and-text.png');
	--logo-2: url('../img/view-360-text-2021-white-bg-600.png'); // Login page - top right
	--logo-3: url('../img/cognitas_logo_2021_3_90.png');
	--logo-4: url('../img/view-360-text-2021.png'); // Main screen - top left
	--logo-4-width: 190px;
	--scrollbar-background-colour: #d9d9dd;
}

// -------------------------------------------------------------------------------------------------

$base-text-color: $off-black;

$paragraph-text-color: $darkgrey;

$login-background-color: var(--login-background-colour);
$login-background-color-top: $white;
$sidebar-background-color: var(--sidebar-colour);
$modal-overlay-background-color: rgba(0, 0, 0, 0.6);

$login-text-color: --login-text-colour;

$login-button-background-color-1: var(--login-button-colour); // idle
$login-button-background-color-2: var(--login-text-colour); // hover
$login-button-background-color-3: $white; // mousedown
$login-button-icon-color: var(--login-button-icon-colour);
$login-button-hover-background-color: var(--login-button-hover-colour);
$login-button-hover-icon-color: var(--login-button-hover-icon-colour);

$header-icon-color: $base-text-color;

$sidebar-button-background-color: transparentize($white, 0.9);
$sidebar-button-hover-background-color: var(--accent-colour-1);
$sidebar-button-selected-color: var(--accent-colour-1);

$config-panel-background-color: transparent;
$button-disabled-color: $darkgrey;
$button-disabled-background: $disabledgrey;

$header-text-color: $white;
$modal-background-color: $white;
$modal-border-color: $darkgrey;

$border-color: var(--border-colour-1);

$input-border-color: var(--border-colour-1);
$input-disabled-border-colour: $grey;
$input-disabled-background: $disabledgrey;
$error-input-border-color: $red;
$active-input-border-color: var(--accent-colour-1);

$list-item-background-color: transparent;
$list-item-selected-background-color: $white;

$selected-item-background-color: var(--accent-colour-2);
$selected-item-text-color: $white;

$button-primary-background: var(--button-colour);
$button-primary-color: $white;

$button-secondary-color: $white;
$button-secondary-background: $darkgrey; // Only on login screen

$alert-background-color: $red;
$alert-text-color: $white;
