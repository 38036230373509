$messages-list-border-colour: var(--border-colour-1);
$messages-list-background-colour: $lightgrey;
$messages-list-border-radius: $group-view-section-border-radius;
$messages-list-padding: 1rem;

.messages-panel {
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	.messages-panel__message-list-container {
		height: 1px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		overflow: hidden;

		border: solid 1px $messages-list-border-colour;
		background-color: $messages-list-background-colour;
		border-radius: $messages-list-border-radius;
	}

	.message-list {
		width: 100%;
		height: 1px;
		flex-grow: 1;
		padding: $messages-list-padding;

		overflow: auto;
	}

	.message {
		margin-bottom: 0.6rem;
	}

	.message:last-child {
		margin-bottom: 0;
	}

	.message-entry {
		margin-top: 0.8rem;
	}
}

.messages-panel--alert {
	.messages-panel__message-list-container {
		animation: messages-panel__flashred steps(1) 1s infinite;
	}
}

/* prettier-ignore */
@keyframes messages-panel__flashred {
	50% {
		border-color: $red;
		border-width: 1px;
		box-shadow: 0px 0px 0px 1px $red;
		z-index: 1;
	}
}
