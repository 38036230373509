$background-colour: var(--header-colour);
$text-colour: var(--header-text-colour);
$border-colour: var(--header-border-colour);
$bottom-border: solid 3px $border-colour;
$group-status-bar-height: 4.5rem;

$group-status-bar-padding: 0px 20px 2px 20px;
$group-status-bar-logo-width: var(--logo-4-width);
$group-status-bar-logo-padding-right: 20px;
$group-status-bar-logo-margin-right: 20px;
$group-status-bar-title-width: 292px;
$group-status-bar-contents-height: 29px;

.group-status-bar {
	height: $group-status-bar-height;
	width: 100%;
	max-width: 100%;
	flex-grow: 0;
	flex-shrink: 0;

	background-color: $background-colour;
	border-bottom: $bottom-border;
	color: $text-colour;
	font-size: $base-font-size;
	line-height: 36px;
	// padding: $group-status-bar-padding;
	// padding-left: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: $base-font-weight;

	&__logo {
		width: $group-status-bar-logo-width;
		padding-right: $group-status-bar-logo-padding-right;
		height: 100%;
		flex-shrink: 0;

		background-image: var(--logo-4);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.case-title {
		font-weight: $base-font-weight;
		flex-shrink: 0;
		padding-left: $group-status-bar-logo-margin-right;
		border-left: 1px solid $border-colour;
		border-right: 1px solid $border-colour;
		height: $group-status-bar-contents-height;
		line-height: $group-status-bar-contents-height;
		// display: flex;
		// align-items: center;
		width: $group-status-bar-title-width;
		max-width: $group-status-bar-title-width;
		padding-right: 1rem;
		@include ellipsisTruncate;
		transform: translateX(-1px);
	}

	.status-bar-message,
	.previous-session-message {
		flex-grow: 1;
		flex-shrink: 1;
		margin: 0 1rem;
	}

	.countdown {
		margin-right: 1rem;
	}

	.label {
		margin-left: 1rem;
		margin-right: 0.5rem;
		white-space: nowrap;
	}

	.value {
		font-weight: bold;
		margin: 0rem;
		color: $text-colour;
	}

	.group-colour {
		margin-left: 32px;
		display: flex;
		align-items: center;
		flex-grow: 1;
	}

	.dot {
		height: 16px;
		width: 16px;
		border-radius: 50%;
		margin-right: 8px;
	}
}
