body.convergent,
body.convergant {
	--accent-colour-1: #a46fad;

	--button-colour: #7f80b8;
	--button-hover-colour: #7f80b8;
	--accent-colour-2: #be587a;
	--button-2-hover-colour: #be587a;

	--header-colour: #2e2c30;
	--header-border-colour: #7f80b8;
	--header-text-colour: white;

	--border-colour-2: #a46fad;

	--heading-colour: #a46fad;
	--heading-border-colour: #a46fad;

	--sidebar-colour: #2d2932;

	--login-button-colour: #be587a;
	--login-button-icon-colour: white;

	--logo-3: url('../../themes/convergent/convergent-logo.png');
	--logo-4: url('../../themes/convergent/convergent-header-logo.png');
	--scrollbar-background-colour: #a46fad30;
}
