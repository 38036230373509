.status-bar-message {
	text-align: center;
	height: 29px;
	line-height: 29px;
	font-weight: bold;
	border-radius: 99px;
	background-color: $alert-background-color;
	color: $alert-text-color;
	padding: 0px;
	padding-top: 1px;
	margin: 0;

	@include ellipsisTruncate;
}
