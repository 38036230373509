.video-call-buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 7px;
	margin-bottom: 6px;

	// padding-top: 16px;
	// padding-bottom: 8px;
	// padding-left: 10px;
	// padding-right: 10px;

	.icon-button {
		// margin: 5px 8px;
		// margin-top: 0;
		// padding: 4px;
		// height: 44px;
		// width: 44px;
		// min-width: 44px;

		margin: 0 6px 5px;
		padding: 7px;
		height: 48px;
		width: 48px;
		min-width: 48px;

		.badge {
			bottom: -4px;
			right: -4px;
		}
	}

	.icon-button {
		border: 1px solid transparent;
	}

	.icon-button:not(.icon-button--selected):not(:hover):not(:active) {
		background-color: white;
		border: 1px solid $mediumgrey;
	}

	/* Hang Up button */
	.video-call-buttons__hang-up.icon-button:not(.icon-button--selected):not(:hover):not(:active) {
		background-color: $red;
		border-color: $red;
		transition: background-color 0ms;

		.icon {
			color: white;
		}
	}
}
