body.axiom {
	--accent-colour-1: #bdd63d;

	--button-colour: #3d3857;
	--button-hover-colour: #573dd6;
	--accent-colour-2: #573dd6;
	--button-2-hover-colour: #745fdd;

	--header-colour: #2e2c30;
	--header-text-colour: white;

	--border-colour-2: #a9c038;

	--heading-colour: #bdd63d;
	--heading-border-colour: #a9c038;
	--heading-text-colour: #2d2932;

	--header-border-colour: #a9c038;

	--sidebar-colour: #2d2932;

	--login-background-colour: #bdd63d;
	--login-button-colour: #3d3857;
	--login-button-icon-colour: white;

	--login-button-hover-colour: #573dd6;
	--login-button-hover-icon-colour: white;

	--logo-1: url('../../themes/axiom/axiom-logo.jpg');
	--logo-3: url('../../themes/axiom/axiom-small.jpg');
	--logo-4: url('../../themes/axiom/axiom-logo-2.jpg');
	--scrollbar-background-colour: #bdd63d40;
}
