.media-openings-modal {
	&__row {
		@include simpleRow();
		background-color: #dddddd;
		color: lighten($base-text-color, 20%);
		border-color: #dddddd;
		.icon {
			color: lighten($base-text-color, 20%);
		}
	}
	&__name {
		flex-grow: 1;
		font-weight: bold;
	}
	.icon {
		flex-grow: 0;
		flex-shrink: 0;
		fill: $base-text-color;
		color: $base-text-color;
	}
}
