.waiting-room-modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	background-color: $modal-overlay-background-color;

	display: flex;
	align-items: center;
	justify-content: center;
	animation: 0.4s fadeInAnim cubic-bezier(0.075, 0.82, 0.165, 1);
	opacity: 1;

	&__main {
		opacity: 1;
		width: 580px;
		max-width: 580px;
		min-width: 0;
		text-align: center;

		animation: 1.3s slideInAnimation cubic-bezier(0.075, 0.82, 0.165, 1);
		transform: translateY(0px);

		border-radius: $xlarge-border-radius;
		padding: 0px;
		background-color: white;
		overflow: hidden;
	}

	&__content {
		padding: 1rem 2rem;
		p {
			font-size: large;
		}
		.message__translate {
			margin: 1.8rem 0 0 0;
		}
	}

	&__header {
		background-color: var(--header-colour);
		border-bottom: solid 3px var(--header-border-colour);
		color: var(--header-text-colour);
		width: 100%;
		height: 4.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__logo {
		background-image: var(--logo-4);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		width: var(--logo-4-width);
		height: 2rem;
	}
}

/* Ensure logout button is still clickable if message is shown */
body.waiting-room-active {
	.group-sidebar__bottom {
		.sidebar-button {
			z-index: 1000;
			color: white;
		}
	}
}

@keyframes slideInAnimation {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}
	50% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}
