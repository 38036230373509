.tutor-logs {
	display: flex;
	width: 100%;
	flex-direction: column;
	> .top {
		// margin-left: 16px;
		padding-left: 16px;
		height: 45px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
	}
	.bottom {
		flex-grow: 1;
	}
	.decision-log {
		flex-grow: 4;
	}
	.groups-list {
		min-width: 230px;
		max-width: 230px;
	}
}
