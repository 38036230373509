.media-modal {
	position: fixed;
	top: 67px; // So that it does not cover title bar
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;

	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	flex-direction: column;

	opacity: 1;
	animation: 0.3s fadeInAnim ease-out;

	.icon-button {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0;
		border-radius: 0;
		.icon {
			color: $white;
			fill: $white;
		}
	}

	&__main {
		flex-grow: 1;
		height: 1px;

		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffffdb;

		img {
			object-fit: contain;
		}
		audio {
			width: 600px;
			max-width: 100%;
			zoom: 1.2;
		}
		img,
		video {
			max-width: calc(100vw - 100px);
			max-height: calc(100vh - (50px + 43px) * 2);
			object-position: center;
		}
	}

	&__bottom {
		display: flex;
		flex-direction: row;
		height: 50px;
		flex-shrink: 0;
		width: 100%;
		text-align: center;
		h2 {
			padding-left: 16px;
			color: white;
			flex-grow: 1;
		}
	}
}

@keyframes fadeInAnim {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.in-side-call .media-modal {
	right: var(--filmstrip-width);
}
