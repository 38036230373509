.tutor-participants {
	@include tutorConfigPanel();
}

.tutor-participants__bottom {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.tutor-participants {
	.dot {
		width: 24px;
		height: 24px;
		border-radius: 99px;
		margin-left: 1rem;
		transition: background-color 500ms ease-out;
	}

	.input select {
		color: black;
		font-size: 1.1rem;
	}

	.participant .left {
		flex-grow: 1;
	}

	.participant .right {
		flex-direction: row;
		align-items: center;
		width: auto;
		flex-grow: 0;
		opacity: 1;

		> * {
			margin: 0;
			margin-left: 1rem;
		}

		.pill {
			min-width: 8.5rem;
		}
	}
}
