@font-face {
	font-family: Montserrat;
	src: url('fonts/montserrat/Montserrat-Light.woff') format('woff');
	font-style: normal;
	font-weight: 300;
	text-rendering: optimizeLegibility;
}
@font-face {
	font-family: Montserrat;
	src: url('fonts/montserrat/Montserrat-Regular.woff') format('woff');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}
@font-face {
	font-family: Montserrat;
	src: url('fonts/montserrat/Montserrat-Medium.woff') format('woff');
	font-style: normal;
	font-weight: 500;
	text-rendering: optimizeLegibility;
}
@font-face {
	font-family: Montserrat;
	src: url('fonts/montserrat/Montserrat-SemiBold.woff') format('woff');
	font-style: normal;
	font-weight: 600;
	text-rendering: optimizeLegibility;
}
@font-face {
	font-family: Montserrat;
	src: url('fonts/montserrat/Montserrat-Bold.woff') format('woff');
	font-style: normal;
	font-weight: 700;
	text-rendering: optimizeLegibility;
}
