.icon-button {
	position: relative;

	height: 40px;
	width: 40px;
	padding: 4px;
	margin: 0;

	border-radius: 99px;

	font-size: 24px;
	font-weight: 200;
	text-align: center;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
	* {
		cursor: pointer;
	}

	.icon {
		background-color: transparent !important;
		color: $base-text-color;
		fill: $base-text-color;
	}

	&:focus,
	&:hover {
		transition: none;
		background-color: var(--button-hover-colour);
		.icon {
			transition: none;
			background-color: var(--button-hover-colour);
			color: var(--button-text-colour);
		}
	}

	&:active,
	&--selected,
	&--selected:hover {
		background-color: var(--accent-colour-2);
		.icon {
			background-color: var(--accent-colour-2);
			color: var(--button-text-colour);
		}
	}

	.badge {
		background-color: $red;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		position: absolute;
		bottom: 6px;
		right: 6px;
		border: solid 2px $sidebar-background-color;
	}
}
