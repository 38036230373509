.login {
	background: hsl(200, 11%, 90%);
	// background: hsl(200, 8%, 17%);
	// background: black;

	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;

	h1 {
		font-family: Rubrik, sans-serif;
		font-weight: 500;
		margin: 2rem 0;
	}

	h2 {
		font-family: Rubrik, sans-serif;
		font-weight: normal;
		font-size: 1.3rem;
		margin: 2rem 0;
	}

	span.icon {
		margin: 0px;
	}

	select:focus,
	input:focus {
		border-color: var(--login-button-colour);
	}

	.language-selector {
		max-width: 100%;

		&__option {
			&--focused,
			&:hover {
				background-color: var(--login-button-colour);
				color: white;
			}
		}
	}

	&__inner {
		height: 100vh;
		width: 100vw;
		// max-width: 1600px;
		display: flex;
		flex-direction: row;
		background: $login-background-color;

		position: relative;
		overflow: hidden;

		@media (min-width: 1600px) {
			max-width: 1400px;
			max-height: 900px;
		}
	}

	&__white-bit {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 66%;
		height: 100%;
		// z-index: 0;
	}

	&__main-logo {
		// width: 100%;
		// height: 400px;
		width: var(--login-logo-width);
		height: 100%;
		background-image: var(--logo-1);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: right center;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	&__left {
		flex-shrink: 0;
		width: 33.33%;
		height: 100%;

		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
	}

	&__right {
		flex-grow: 0;
		position: relative;
		width: 320px;
		margin-left: 33.33%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h1 {
			font-size: 3rem;
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: -1rem;
	}

	&__content,
	&__main,
	&__tutor {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: stretch;
		justify-content: center;
		text-align: center;
	}

	&__content {
		height: 364px;
		text-align: center;
		font-size: 1.3rem;
	}

	&__main {
		margin-top: 3rem;
	}

	&__tutor {
		.login__main {
			span.label {
				margin-bottom: 0px;
			}
		}
	}

	&__input-row {
		display: flex;
		align-items: center;
		.input {
			flex-grow: 1;
			margin: 8px;
		}
		.icon {
			opacity: 0.5;
			margin-right: 16px;
			position: absolute;
			left: -25px;
			width: 20px;
			height: 20px;
			fill: var(--login-text-colour);
			color: var(--login-text-colour);
		}
	}

	&__error-space {
		overflow: visible;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 58px;
		.error {
			max-width: none;
			position: absolute;
			min-width: calc(100% - 14px);
		}
	}

	&__otherbuttons {
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		align-items: center;
		font-size: 18px;
		width: 100%;

		.button {
			flex-grow: 1;
			margin: 4px;
			width: 50%;
		}

		.button.button--secondary {
			button {
				background-color: #888888;
				&:hover {
					background: var(--login-button-hover-colour);
				}
			}
		}
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.8);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__version {
		position: absolute;
		right: 14px;
		bottom: 7px;
		text-transform: uppercase;
		font-size: 0.85rem;
		opacity: 0.7;
		letter-spacing: 1px;
	}
}

// =================================================================================================
// Fade out login details when --busy
.login {
	.login__content {
		opacity: 1;
		transition: opacity 300ms;
	}
}
.login--busy {
	.login__content {
		opacity: 0.2;
		cursor: wait;
		pointer-events: none;
	}
}

// =================================================================================================

$fadeInDuration: 300ms;

.login__fadeIn-enter {
	opacity: 0;
}

.login__fadeIn-enter-active {
	opacity: 1;
	transition: opacity $fadeInDuration;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login__fadeIn-enter-done {
	opacity: 1;
}

.login__fadeIn-exit {
	opacity: 1;
}

.login__fadeIn-exit-active {
	opacity: 0;
	transition: opacity $fadeInDuration;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login__fadeIn-exit-done {
	opacity: 0;
}

// =================================================================================================

.login__slideinfromright-enter {
	opacity: 0;
	transform: translateX(200px);
}

.login__slideinfromright-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login__slideinfromright-enter-done {
	transform: translateX(0);
	opacity: 1;
}

.login__slideinfromright-exit {
	transform: translateX(0);
	opacity: 1;
}

.login__slideinfromright-exit-active {
	opacity: 0;
	transform: translateX(200px);
	transition: opacity 300ms, transform 300ms;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login__slideinfromright-exit-done {
	opacity: 0;
	transform: translateX(200px);
}

.login__slideinfromright-enter,
.login__slideinfromright-enter-active,
.login__slideinfromright-enter-done,
.login__slideinfromright-exit,
.login__slideinfromright-exit-active,
.login__slideinfromright-exit-done,
.login__slideinfromright-appear,
.login__slideinfromright-appear-active,
.login__slideinfromright-appear-done {
	position: absolute;
	width: 100%;
}

.login__slideinfromleft-enter {
	opacity: 0;
	transform: translateX(-200px);
}

.login__slideinfromleft-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login__slideinfromleft-enter-done {
	transform: translateX(0);
	opacity: 1;
}

.login__slideinfromleft-exit {
	transform: translateX(0);
	opacity: 1;
}

.login__slideinfromleft-exit-active {
	opacity: 0;
	transform: translateX(-200px);
	transition: opacity 300ms, transform 300ms;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login__slideinfromleft-exit-done {
	opacity: 0;
	transform: translateX(-200px);
}

.login__slideinfromleft-enter,
.login__slideinfromleft-enter-active,
.login__slideinfromleft-enter-done,
.login__slideinfromleft-exit,
.login__slideinfromleft-exit-active,
.login__slideinfromleft-exit-done,
.login__slideinfromleft-appear,
.login__slideinfromleft-appear-active,
.login__slideinfromleft-appear-done {
	position: absolute;
	width: 100%;
}
