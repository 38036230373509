$countdown-background-colour: $white;
$countdown-text-colour: $base-text-color;
$countdown-border: solid 1px var(--header-border-colour);
$countdown-border-radius: 99px;
$countdown-font-size: 19px;
$countdown-letter-spacing: 2px;
$countdown-font-weight: 600;
$countdown-padding: 3px 0 0 0;

.countdown {
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: $countdown-background-colour;
	border-radius: $countdown-border-radius;
	border: none;

	padding: $countdown-padding;

	color: $countdown-text-colour;
	font-size: $countdown-font-size;
	font-weight: $countdown-font-weight;
	letter-spacing: $countdown-letter-spacing;

	height: auto;
	line-height: 24px;
	width: 9.8rem;

	box-sizing: border-box;

	&--paused {
		animation: blinker 1.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
	}

	&--nearzero {
		animation: flashred 1s steps(1) infinite;
	}

	&--zero {
		background-color: transparent;
		color: var(--header-border-colour);
		border: solid 1px var(--header-border-colour);
	}

	@keyframes blinker {
		50% {
			opacity: 0.1;
		}
	}

	@keyframes flashred {
		50% {
			color: $white;
			background-color: $red;
			border-color: $red;
		}
	}
}
