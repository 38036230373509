@use 'sass:math';

$input-border-radius: 99px;
$horizontalSpacing: 1rem;

.decision-maker {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	width: 45rem;
	margin: auto;

	> * {
		margin: 0 math.div($horizontalSpacing, 2);
	}

	label {
		white-space: nowrap;
	}
	h3 {
		min-width: 160px;
		max-width: 160px;
	}

	div:nth-child(2) {
		flex-grow: 1;
		max-width: 300px;
	}

	.input,
	&__readonly {
		margin: 0 math.div($horizontalSpacing, 2);
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: 400px;
	}

	.input input,
	&__readonly {
		border-radius: $input-border-radius;
		border: solid 1px $input-disabled-border-colour;
		padding: 0.5rem 1rem;
	}

	&__readonly {
		cursor: normal;
		background-color: $input-disabled-background;
		line-height: normal;
		outline: none;
	}

	.button {
		button {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.input {
		input:focus {
			border: solid 1px var(--accent-colour-1);
		}
	}

	.input--error {
		input {
			border-color: $error-input-border-color;
		}
	}
}
