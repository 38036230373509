.icon {
	color: white;

	svg {
		width: 100%;
		height: 100%;
	}

	&-file {
		@extend .icon;
		// @extend .fa;
		// @extend .fa-file-text;
	}

	&-file-outline {
		@extend .icon;
		// @extend .fa;
		// @extend .fa-file-text-o;
	}

	&.fa-toggle-off {
		@extend .icon;
		// @extend .fa;
		// @extend .fa-toggle-on;
		transform: rotate(180deg);
	}
}
