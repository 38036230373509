.tab__row {
	display: flex;
	transform: translateY(1px);
	// margin-top: 0.8rem;
}

.tab {
	border: solid 1px #cccccc;
	padding: 2px 2rem 0 2rem;
	border-top-left-radius: $small-border-radius;
	border-top-right-radius: $small-border-radius;
	margin-right: 0.6rem;
	transition: border-color 300ms, background-color 300ms;
	cursor: pointer;

	* {
		cursor: pointer;
	}

	h3 {
		line-height: 2.5rem;
		margin: 0;
		font-family: $base-font-family;
	}
}

.tab--inactive {
	background-color: $white;

	&:hover {
		transition: none;
		border-top-color: var(--accent-colour-1);
		border-right-color: var(--accent-colour-1);
		border-left-color: var(--accent-colour-1);
		background-color: var(--accent-colour-1);
		color: white;
	}
}

.tab--active {
	border: solid 1px var(--accent-colour-2);
	background-color: var(--accent-colour-2);
	color: white;
}

.tab__content--active {
	border-top-left-radius: 0;
}

.tab__content--inactive {
	display: none;
}
