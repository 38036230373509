.wait-message {
	background-color: white;
	border-radius: $xlarge-border-radius;
	border: none;
	min-width: 500px;
	max-width: 80%;
	height: auto;
	padding: 0.5rem;

	&__inner {
		border: solid 1px var(--border-colour-2);
		border-radius: calc(#{$xlarge-border-radius} - 4px);
		align-items: center;
		justify-content: center;
		flex-direction: column;
		pointer-events: all;
		text-align: center;
		p {
			flex-grow: 1;
			padding: 0rem 1rem;
			white-space: pre-wrap;
		}
		p,
		h3 {
			margin: 1.2rem 0;
		}
		p:first-of-type {
			margin-top: 0;
		}
		.bubble-heading {
			margin-bottom: 1.2rem;
			margin-top: 0.4rem;
		}
	}
}

.wait-message-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.4);
}
