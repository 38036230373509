body.hmrc {
	--accent-colour-1: #008786;

	--button-colour: #3a4e5f;
	--button-hover-colour: #004e92;
	--accent-colour-2: #1d70b8;
	--button-2-hover-colour: #0088ff;

	--header-colour: #2e2c30;
	--header-text-colour: white;
	--header-border-colour: #1d70b8;

	--border-colour-2: #008786;

	--heading-colour: #008786;
	--heading-border-colour: #008786;

	--sidebar-colour: #2d2932;

	--login-background-colour: #008786;
	--login-button-colour: #262828;
	--login-button-hover-colour: #1d70b8;
	--login-button-icon-colour: white;

	--logo-1: url('../../themes/hmrc/hmrc-white.png');
	--logo-3: url('../../themes/hmrc/hmrc-alt-small.png');
	--logo-4: url('../../themes/hmrc/hmrc-alt-white-small.png');
	--scrollbar-background-colour: #00878630;
}
