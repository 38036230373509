@use 'sass:math';

$background-color: $white;
$left-section-border-color: var(--border-colour-1);
$decision-section-border-color: var(--border-colour-1);
$decision-section-border-radius: $group-view-section-border-radius;

$left-section-background-color: $lightgrey;
$decision-section-background-color: $lightlightgrey;

$section-padding: $vertical-spacing 1.3rem 1.3rem 1.3rem;

$left-section-width: 400px;

.group-view {
	display: flex;
	flex-direction: column;
	width: 1px;
	flex-grow: 1;
	position: relative;

	label {
		font-size: 16px;
		text-transform: none;
		letter-spacing: normal;
		font-family: $heading-font-family;
		color: var(--heading-text-colour);
	}

	&__top {
		display: flex;
		flex-shrink: 0;
	}

	&__middle {
		display: flex;
		flex-direction: row;
		height: 1px;
		flex-grow: 1;

		.bubble-heading {
			font-size: 18px;
			margin-top: -12px;
			height: 38px;
			line-height: 38px;
		}
	}

	&__left {
		display: flex;
		flex-direction: column;
		align-items: center;

		min-width: $left-section-width;
		max-width: $left-section-width;
		padding: $section-padding;

		background-color: $left-section-background-color;
		border-left: solid 1px $left-section-border-color;

		position: relative;

		> .bubble-heading {
			margin-bottom: -2px;
		}

		.media-feed {
			width: 100%;
			margin-bottom: $vertical-spacing;
			max-height: calc(50vh - 9rem);
			height: calc(50vh - 9rem);
		}

		.messages-panel {
			width: 100%;
		}
		.messages-panel__message-list-container {
			background-color: $white;
		}
		.message-list {
			padding: 0.5rem;
		}
		.message {
			margin-bottom: math.div($vertical-spacing, 4);
			padding-right: 4px;
		}
		.message:last-child {
			margin-bottom: 0;
		}
		.message .message__left {
			padding-top: 7px;
		}
	}

	&__main {
		position: relative;
		z-index: 0;
		display: flex;
		width: 0px;
		flex-grow: 1;
		flex-direction: column;
		// background-color: $background-color;
		background-color: $left-section-background-color;
		overflow-y: hidden;
		padding: $section-padding;
		align-items: center;
		padding-left: 0;
	}

	&__main-inner {
		width: 100%;
		height: 0px;
		flex-grow: 1;

		min-width: 500px;
		max-width: 1300px;
		display: flex;
		flex-direction: column;
		align-items: center;

		> * {
			width: 100%;
		}

		> .bubble-heading {
			z-index: 2;
			margin-bottom: -2px;
			width: auto;
		}
	}

	&__main,
	&__left {
		padding-top: 1.2rem;
	}

	&__decision-section {
		height: 0px;
		flex-grow: 1;

		display: flex;
		flex-direction: column;

		border: solid 1px $decision-section-border-color;
		border-bottom-left-radius: $decision-section-border-radius;
		border-bottom-right-radius: $decision-section-border-radius;
		background-color: $decision-section-background-color;

		margin-top: -1px;

		padding: 0;
		padding-bottom: 0;
		overflow: hidden;

		.decision-maker,
		.decision-log__header {
			padding: $section-padding;
			padding-top: 0;
			padding-bottom: 0;
		}

		.decision-maker {
			margin-top: 1.4rem;
		}

		.decision-log__header {
			margin-top: 1.8rem;
		}

		.decision-log__scroller {
			margin-top: 0.66rem;
		}

		.decision-log__inner {
			padding: $section-padding;
			padding-top: 0;
			padding-bottom: 0;
		}

		> .bubble-heading {
			margin-top: 0;
		}
	}

	.video-call {
		position: relative;
		height: 100%;
		flex-grow: 1;
	}
}
