$content-border-radius: $group-view-section-border-radius;

.in-side-call .update-modal {
	right: var(--filmstrip-width);
}

.update-modal {
	position: fixed;
	top: 68px; // So that it does not cover title bar
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	flex-direction: column;
	z-index: 2;

	opacity: 1;
	animation: 0.3s fadeInAnim ease-out;

	.icon-button {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0;
		border-radius: 0;
		.icon {
			color: $white;
			fill: $white;
		}
	}

	&__main {
		flex-grow: 1;
		height: 1px;

		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
		padding-top: 20vh;
		color: #ffffffdb;
		z-index: 100;
		.bubble-heading {
			color: white;
		}
	}

	&__content {
		border: solid 1px var(--accent-colour-1);
		padding: 1.5rem 3rem;
		border-radius: $xlarge-border-radius;
		background-color: $white;
		color: $base-text-color;
		margin-top: 2rem;
		width: 500px;
		min-height: 70px;

		font-size: 1.2rem;
		line-height: 1.6rem;
		word-break: break-word;
		white-space: pre-wrap;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

@keyframes fadeInAnim {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
