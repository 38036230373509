.prev-session {
	@extend .media-item;
	display: flex;
	flex-direction: column;

	.right {
		text-align: right;
		display: flex;
		flex-direction: column;
		.button {
			margin: 2px;
		}
		.icon-button {
			margin-left: 10px;
			margin-right: -8px;
		}
	}

	h2 {
		margin: 4px 0px 8px 0px;
	}

	&__time {
		opacity: 0.6;
	}

	&.prev-session--selected {
		background-color: $selected-item-background-color;
		color: $selected-item-text-color;
		h2 {
			color: $selected-item-text-color;
		}
		.icon {
			color: $selected-item-text-color;
		}
		border-color: transparent;
	}
}

.prev-session.prev-session {
	padding-left: 16px;
}
