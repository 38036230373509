$hill_blue: #0a123e;
$hill_purple: #302543;
$hill_gold: #a79368;

body.hill {
	--accent-colour-1: #{$hill_gold};

	--button-colour: #{lighten($hill_purple, 10%)};
	--button-hover-colour: #{lighten($hill_purple, 25%)};
	--accent-colour-2: #{$hill_gold};
	--button-2-hover-colour: #{darken($hill_gold, 5%)};

	--header-colour: #{$hill_blue};
	--header-text-colour: white;
	--header-border-colour: #{$hill_gold};

	--border-colour-2: #{$hill_gold};

	--heading-colour: #{$hill_blue};
	--heading-text-colour: #{$hill_blue};
	--heading-border-colour: #{$hill_gold};

	--sidebar-colour: #{darken($hill_blue, 7%)};

	--login-button-colour: #{$hill_gold};
	--login-button-icon-colour: white;
	--login-button-hover-colour: #{$hill_purple};
	--login-logo-width: 300px;

	--login-background-colour: #{$hill_blue};
	--logo-4-width: 275px;
	--logo-1: url('../../themes/hill/hill_associates_logo_6.png');
	--logo-3: url('../../themes/hill/hill_associates_logo_3.png');
	--logo-4: url('../../themes/hill/hill_associates_logo_5.png');
	--scrollbar-background-colour: #{transparentize($hill_purple, 0.9)};
}
