@import '../fonts/lato/lato';
@import '../fonts/rubrik/rubrik';
@import '../fonts/montserrat/montserrat';
@import '../fonts/icomoon/style';

$fa-font-path: 'fonts/font-awesome-subset/webfonts/';
@import '../fonts/font-awesome-subset/scss/fontawesome.scss';
@import '../fonts/font-awesome-subset/scss/light.scss';
@import '../fonts/font-awesome-subset/scss/brands.scss';
@import '../fonts/font-awesome-subset/scss/solid.scss';

$base-font-family: Lato, Calibri, sans-serif;
$heading-font-family: Montserrat, sans-serif;
$base-line-height: 17px;
$base-font-weight: 500;
$heading-font-weight: 600;

$base-font-size: 15px;

$button-font-size: 13px;
$heading-font-size: 22px;
$primary-heading-size: 18px;
$subheading-font-size: 16px;
$small-font-size: 13px;

html {
	font-family: $base-font-family;
	line-height: $base-line-height;
	font-weight: $base-font-weight;
	font-size: $base-font-size;
	color: $base-text-color;
}

h2 {
	font-family: $heading-font-family;
	font-size: $primary-heading-size;
	font-weight: $heading-font-weight;
	color: var(--heading-text-colour);
}

h3 {
	font-family: $heading-font-family;
	font-size: $subheading-font-size;
	margin: 8px 0px;
}

p {
	font-size: $base-font-size;
	line-height: $base-line-height * 1.2;
	color: $paragraph-text-color;
}

label {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding-left: 3px;
	padding-top: 1px;
}
