.icon-select {
	width: 5rem;

	.icon {
		width: 35px;
		height: 35px;
		line-height: 35px;

		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 99px;

		&:before {
			transform: translate(0.5px, 0px);
		}
	}

	.icon-select,
	.icon-select____control {
		min-height: 50px;
	}

	.icon-select____control {
		border: solid 1px $input-border-color;
	}

	.icon-select__selected-value {
		display: flex;
		align-items: center;
		flex-grow: 1;
		justify-content: center;
		// padding-left: 0.5rem;
	}

	.icon-select__option {
		// This has !important to override the [class*=" icon-"], [class^=icon-] { font-family: icomoon!important;
		// styling imposed by our icomoon icon set.
		font-family: $base-font-family !important;

		padding-top: 0.1rem;
		padding-bottom: 0.1rem;
		display: flex;
		align-items: center;
		padding-left: 0.5rem;

		.icon {
			margin-right: 0.5rem;
		}
	}

	.icon-select____menu {
		width: 15rem;
	}

	.icon-select____indicator {
		padding: 8px 4px 8px 0px;
	}

	.icon-select____indicator-separator {
		display: none;
	}

	.icon-select____value-container {
		flex-wrap: nowrap;
	}

	.icon-select____control--is-focused,
	.icon-select____control:hover {
		border-color: var(--accent-colour-1);
		box-shadow: 0 0 0 1px var(--accent-colour-1);
	}
}
