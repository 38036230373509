/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

.#{$fa-css-prefix}-arrow-circle-left:before { content: fa-content($fa-var-arrow-circle-left); }
.#{$fa-css-prefix}-arrow-left:before { content: fa-content($fa-var-arrow-left); }
.#{$fa-css-prefix}-at:before { content: fa-content($fa-var-at); }
.#{$fa-css-prefix}-briefcase:before { content: fa-content($fa-var-briefcase); }
.#{$fa-css-prefix}-camera:before { content: fa-content($fa-var-camera); }
.#{$fa-css-prefix}-chalkboard-teacher:before { content: fa-content($fa-var-chalkboard-teacher); }
.#{$fa-css-prefix}-check:before { content: fa-content($fa-var-check); }
.#{$fa-css-prefix}-clipboard-list:before { content: fa-content($fa-var-clipboard-list); }
.#{$fa-css-prefix}-cog:before { content: fa-content($fa-var-cog); }
.#{$fa-css-prefix}-comments-alt:before { content: fa-content($fa-var-comments-alt); }
.#{$fa-css-prefix}-copy:before { content: fa-content($fa-var-copy); }
.#{$fa-css-prefix}-desktop:before { content: fa-content($fa-var-desktop); }
.#{$fa-css-prefix}-download:before { content: fa-content($fa-var-download); }
.#{$fa-css-prefix}-envelope:before { content: fa-content($fa-var-envelope); }
.#{$fa-css-prefix}-facebook-f:before { content: fa-content($fa-var-facebook-f); }
.#{$fa-css-prefix}-file-alt:before { content: fa-content($fa-var-file-alt); }
.#{$fa-css-prefix}-globe:before { content: fa-content($fa-var-globe); }
.#{$fa-css-prefix}-history:before { content: fa-content($fa-var-history); }
.#{$fa-css-prefix}-images:before { content: fa-content($fa-var-images); }
.#{$fa-css-prefix}-instagram:before { content: fa-content($fa-var-instagram); }
.#{$fa-css-prefix}-key:before { content: fa-content($fa-var-key); }
.#{$fa-css-prefix}-linkedin:before { content: fa-content($fa-var-linkedin); }
.#{$fa-css-prefix}-map-marker-alt:before { content: fa-content($fa-var-map-marker-alt); }
.#{$fa-css-prefix}-microphone:before { content: fa-content($fa-var-microphone); }
.#{$fa-css-prefix}-microphone-slash:before { content: fa-content($fa-var-microphone-slash); }
.#{$fa-css-prefix}-newspaper:before { content: fa-content($fa-var-newspaper); }
.#{$fa-css-prefix}-paper-plane:before { content: fa-content($fa-var-paper-plane); }
.#{$fa-css-prefix}-phone:before { content: fa-content($fa-var-phone); }
.#{$fa-css-prefix}-power-off:before { content: fa-content($fa-var-power-off); }
.#{$fa-css-prefix}-skype:before { content: fa-content($fa-var-skype); }
.#{$fa-css-prefix}-th:before { content: fa-content($fa-var-th); }
.#{$fa-css-prefix}-times:before { content: fa-content($fa-var-times); }
.#{$fa-css-prefix}-trash-alt:before { content: fa-content($fa-var-trash-alt); }
.#{$fa-css-prefix}-twitter:before { content: fa-content($fa-var-twitter); }
.#{$fa-css-prefix}-user:before { content: fa-content($fa-var-user); }
.#{$fa-css-prefix}-user-plus:before { content: fa-content($fa-var-user-plus); }
.#{$fa-css-prefix}-users:before { content: fa-content($fa-var-users); }
.#{$fa-css-prefix}-viber:before { content: fa-content($fa-var-viber); }
.#{$fa-css-prefix}-video:before { content: fa-content($fa-var-video); }
.#{$fa-css-prefix}-video-slash:before { content: fa-content($fa-var-video-slash); }
.#{$fa-css-prefix}-volume:before { content: fa-content($fa-var-volume); }
.#{$fa-css-prefix}-volume-down:before { content: fa-content($fa-var-volume-down); }
.#{$fa-css-prefix}-volume-mute:before { content: fa-content($fa-var-volume-mute); }
.#{$fa-css-prefix}-volume-slash:before { content: fa-content($fa-var-volume-slash); }
.#{$fa-css-prefix}-volume-up:before { content: fa-content($fa-var-volume-up); }
.#{$fa-css-prefix}-whatsapp:before { content: fa-content($fa-var-whatsapp); }
.#{$fa-css-prefix}-youtube:before { content: fa-content($fa-var-youtube); }
