.textarea {
	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: 4px;
	margin-bottom: 8px;

	textarea {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		resize: none;
	}

	.textarea__resizer,
	textarea {
		white-space: pre-wrap;
		overflow-wrap: break-word;
		line-height: normal;

		margin: 0;
		padding: 8px 10px;
	}

	.textarea__resizer {
		opacity: 0;
		border: solid 1px transparent;
	}
}
