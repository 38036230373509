.login-group {
	// display: flex;
	// flex-direction: column;
	// align-items: stretch;
	// height: 360px;
	// justify-content: center;

	&__main {
		@extend .login__main;
		span {
			text-align: center;
			margin-bottom: 20px;
		}
	}
}
